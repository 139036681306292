export const WORDS = [
  'oomf',
  'spoomf',
  'toki',
  'kasoku',
  'tokidoki',
  'sunscreen',
  'twister',
  'chromablur',
  'preds',
  'elixir',
  'coal',
  'haharama',
  'karma',
  'lost',
  'sarah',
  'arg',
  'lux',
  'rankdown',
  'midweek',
  'final',
  'yearend',
  'twangy',
  'jobless',
  'totc',
  'chart',
  'global',
  'pop',
  'kpop',
  'rap',
  'rnb',
  'country',
  'aaaaa',
  'star',
  'stars',
  'starboard',
  'bait',
  'me',
  'zach',
  'menchies',
  'kika',
  'smoov',
  'ashres',
  'zero',
  'remy',
  'tom',
  'khaiwee',
  'mason',
  'lotte',
  'jerome',
  'leith',
  'ben',
  'naomi',
  'empress',
  'luka',
  'emmie',
  'sam',
  'ate',
  'rock',
  'zeum',
  'ijbol',
  'samson',
  'dance',
  'szastan',
  'gikem',
  'alternative',
  'drem',
  'nevada',
  'sales',
  'nicko',
  'satsuki',
  'tanked',
  'nbnc',
  'esmitty',
  'jamo',
  'queen',
  'skyler',
  'beam',
  'mode',
  'khia',
  'awards',
  'reveluv',
  'rowan',
  'modmail',
  'loss',
  'jockie',
  'charts',
  'sale',
  'anthony',
  'matt',
  'feather',
  'debut',
  'perplex',
  'totcle',
  'anohre',
  'quasi',
  'danny',
  'tank',
  'caiden',
  'goober',
  'style',
  'leonida',
  'fox',
  'youtuber',
  'nathan',
  'smashhit',
  'flop',
  'staff',
  'fanstar',
  'wwbs',
  'rulefive',
  'woc',
  'locked',
  'roadrunner',
  'turnofthetv',
  'mother',
  'eagle',
  'sean',
  'katie',
  'sirum',
  'jack',
  'streams',
  'lukas',
  'fanstar',
  'airplay',
  'escapism',
  'hidbid',
  'ivy',
  'isol',
  'pavle',
  'nadeko',
  'lo',
  'gain',
  'mona',
  'nachobot',
  'bigg',
  'kanubow',
  'voice',
  'ramona',
  'electronic',
  'weeks',
  'andorra',
  'andrea',
  'payola',
  'kosek',
  'braylon',
  'units',
  'among',
  'edm',
  'bacon',
  'peak',
  'radio',
  'emilio',
  'mj',
  'ragbeer',
  'xuggy',
  'indie',
  'razor',
  'trisk',
  'blake',
  'ariswift',
  'suicune',
  'jay',
  'indub',
  'dave',
  'mike',
  'vc',
  'jonah',
  'hit',
  'violet',
  'chat',
  'cherry',
  'brian',
  'julie',
  'stan',
  'ayen',
  'hiphop',
  'music',
  'gourmet',
  'latin',
  'chuu',
  'rek',
  'duarte',
  'lip',
  'river',
  'aneesh',
  'runzr',
  'flopped',
  'goat',
  'personal',
  'kelly',
  'macie',
  'nacho',
  'joomf',
  'khan',
  'stream',
  'jon',
  'oceanic',
  'general',
  'jelly',
  'synch',
  'xylo',
  'prediction',
  'rules',
  'jpop',
  'stable',
  'huggies',
]
