export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['popped off', 'stream twister by tokidoki', 'how']
export const GAME_COPIED_MESSAGE = 'copied to clipboard go share it i guess'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'can you not count correctly'
export const WORD_NOT_FOUND_MESSAGE = 'invalid word try again...'
export const HARD_MODE_ALERT_MESSAGE =
  'if you wanted hard mode you shouldve enabled it earlier'
export const HARD_MODE_DESCRIPTION =
  'any hints you get you have to use in the following guesses'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'for improved color vision'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `the word was ${solution} smh`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `you gotta use ${guess} in position ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `your guess has to have ${letter}`
export const ENTER_TEXT = 'enter'
export const DELETE_TEXT = 'delete'
export const STATISTICS_TITLE = 'stats'
export const GUESS_DISTRIBUTION_TEXT = 'guess distribution'
export const NEW_WORD_TEXT = 'new word in'
export const SHARE_TEXT = 'share'
export const SHARE_FAILURE_TEXT =
  'sharing results failed. this feature should work with most browsers that support HTTPS.'
export const MIGRATE_BUTTON_TEXT = 'transfer'
export const MIGRATE_DESCRIPTION_TEXT =
  'click here to transfer your stats to a new device!'
export const TOTAL_TRIES_TEXT = 'total tries'
export const SUCCESS_RATE_TEXT = 'success rate'
export const CURRENT_STREAK_TEXT = 'current streak'
export const BEST_STREAK_TEXT = 'best streak'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "you are using an embedded browser and may experience problems sharing or saving your results. we encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = 'choose a past date'
export const DATEPICKER_CHOOSE_TEXT = 'choose'
export const DATEPICKER_TODAY_TEXT = 'today'
export const ARCHIVE_GAMEDATE_TEXT = 'game date'
