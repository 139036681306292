import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="how to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        you have 6 guesses to try and guess whatever word was randomly picked
        as theres a new word everyday, could be the name of a member or music related
      </p>
      
      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="C"
          status="correct"
        />
        <Cell value="H" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell value="T" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        the letter C is in the word and in the correct spot
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="D" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="C" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        the letter N is in the word but in the wrong spot
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="M" isCompleted={true} />
        <Cell value="U" isCompleted={true} />
        <Cell value="S" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="I" status="absent" />
        <Cell value="C" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        the letter I is not in the word in any spot
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        this is a unofficial game and NOT made by anyone on the TOTC team.
        -{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="font-bold underline"
        >
          made using reactle
        </a>{' '}
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
         
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        {' '}
        <a
          href="https://discord.com/invite/talkofthecharts"
          className="font-bold underline"
        >
          <br></br>join the totc discord server!!!
        </a>{' '}
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
         
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        {' '}
        <a
          href="https://github.com/totcle/totcle-word-list"
          className="font-bold underline"
        >
          <br></br>totcle word list (if you get stuck)
        </a>{' '}
      </p>
    </BaseModal>
  )
}
