export const VALID_GUESSES = [
  'oomf',
  'spoomf',
  'toki',
  'kasoku',
  'tokidoki',
  'sunscreen',
  'twister',
  'chromablur',
  'preds',
  'elixir',
  'coal',
  'haharama',
  'karma',
  'lost',
  'sarah',
  'arg',
  'lux',
  'rankdown',
  'midweek',
  'final',
  'yearend',
  'twangy',
  'jobless',
  'totc',
  'chart',
  'global',
  'pop',
  'kpop',
  'rap',
  'rnb',
  'country',
  'house',
  'star',
  'stars',
  'starboard',
  'bait',
  'zach',
  'menchies',
  'kika',
  'smoov',
  'ashres',
  'zero',
  'remy',
  'tom',
  'khaiwee',
  'mason',
  'lotte',
  'jerome',
  'leith',
  'ben',
  'naomi',
  'empress',
  'rek',
  'drem',
  'lo',
  'duarte',
  'jelly',
  'style',
  'pavle',
  'jay',
  'fanstar',
  'razor',
  'mj',
  'mike',
  'joomf',
  'anohre',
  'bacon',
  'ariswift',
  'caiden',
  'xuggy',
  '24kgoldn',
  'among',
  'stable',
  'flop',
  'flopped',
  'tank',
  'tanked',
  'rules',
  'jockie',
  'edm',
  'electronic',
  'dance',
  'music',
  'personal',
  'general',
  'vc',
  'voice',
  'chat',
  'rock',
  'alternative',
  'jpop',
  'ate',
  'mother',
  'hit',
  'smashhit',
  'queen',
  'goat',
  'river',
  'locked',
  'debut',
  'gain',
  'loss',
  'peak',
  'weeks',
  'woc',
  'units',
  'stream',
  'streams',
  'sale',
  'sales',
  'radio',
  'airplay',
  'payola',
  'stan',
  'lip',
  'latin',
  'hiphop',
  'feather',
  'escapism',
  'jack',
  'prediction',
  'fanstar',
  'goober',
  'leonida',
  'modmail',
  'roadrunner',
  'kanubow',
  'eagle',
  'sam',
  'sirum',
  'sean',
  'esmitty',
  'mona',
  'turnofthetv',
  'gikem',
  'katie',
  'nicko',
  'satsuki',
  'szastan',
  'andorra',
  'blake',
  'kosek',
  'zeum',
  'quasi',
  'ayen',
  'chuu',
  'nacho',
  'nachobot',
  'nadeko',
  'trisk',
  'rowan',
  'emmie',
  'khia',
  'awards',
  'indie',
  'staff',
  'youtuber',
  'julie',
  'ragbeer',
  'perplex',
  'emilio',
  'charts',
  'khaiwe',
  'ramona',
  'totcle',
  'rulefive',
  'nevada',
  'andrea',
  'wwbs',
  'macie',
  'fox',
  'nathan',
  'bigg',
  'jon',
  'anthony',
  'danny',
  'kelly',
  'beam',
  'nbnc',
  'oceanic',
  'ivy',
  'isol',
  'dave',
  'samson',
  'aneesh',
  'braylon',
  'hidbid',
  'jamo',
  'skyler',
  'runzr',
  'khan',
  'cherry',
  'violet',
  'brian',
  'gourmet',
  'lukas',
  'matt',
  'synch',
  'luka',
  'indub',
  'suicune',
  'huggies',
  'reveluv',
  'jonah',
  'mode',
  'xylo',
  'ijbol',
  'of',
  'to',
  'in',
  'is',
  'on',
  'by',
  'it',
  'or',
  'be',
  'at',
  'as',
  'an',
  'we',
  'us',
  'if',
  'my',
  'do',
  'no',
  'he',
  'up',
  'so',
  'am',
  'me',
  're',
  'go',
  'la',
  'id',
  'et',
  'oh',
  'ad',
  'ok',
  'hi',
  'pa',
  'ma',
  'ed',
  'ex',
  'ha',
  'ah',
  'sh',
  'pi',
  'ai',
  'um',
  'yo',
  'uh',
  'aw',
  'about',
  'act',
  'add',
  'after',
  'again',
  'air',
  'all',
  'also',
  'and',
  'animal',
  'answer',
  'any',
  'are',
  'ask',
  'back',
  'been',
  'before',
  'between',
  'big',
  'boy',
  'build',
  'but',
  'call',
  'came',
  'can',
  'cause',
  'change',
  'city',
  'close',
  'come',
  'could',
  'cover',
  'cross',
  'day',
  'did',
  'differ',
  'does',
  'dont',
  'down',
  'draw',
  'each',
  'earth',
  'eight',
  'end',
  'even',
  'every',
  'eye',
  'far',
  'farm',
  'father',
  'few',
  'find',
  'first',
  'five',
  'follow',
  'food',
  'for',
  'form',
  'found',
  'four',
  'from',
  'get',
  'give',
  'good',
  'great',
  'grow',
  'had',
  'hand',
  'hard',
  'has',
  'have',
  'help',
  'her',
  'here',
  'high',
  'him',
  'his',
  'home',
  'hot',
  'how',
  'just',
  'keep',
  'kind',
  'know',
  'land',
  'large',
  'last',
  'late',
  'learn',
  'left',
  'let',
  'life',
  'light',
  'like',
  'line',
  'little',
  'live',
  'long',
  'look',
  'low',
  'made',
  'make',
  'man',
  'many',
  'may',
  'mean',
  'men',
  'might',
  'more',
  'most',
  'move',
  'much',
  'must',
  'name',
  'near',
  'need',
  'never',
  'new',
  'night',
  'nine',
  'north',
  'now',
  'number',
  'off',
  'old',
  'one',
  'only',
  'other',
  'our',
  'out',
  'over',
  'own',
  'page',
  'part',
  'people',
  'picture',
  'place',
  'plant',
  'play',
  'point',
  'port',
  'press',
  'put',
  'read',
  'real',
  'right',
  'round',
  'run',
  'said',
  'same',
  'saw',
  'say',
  'school',
  'sea',
  'see',
  'self',
  'sentence',
  'set',
  'seven',
  'she',
  'should',
  'show',
  'side',
  'six',
  'small',
  'some',
  'sound',
  'spell',
  'stand',
  'start',
  'state',
  'still',
  'story',
  'study',
  'such',
  'sun',
  'take',
  'tell',
  'ten',
  'than',
  'that',
  'the',
  'their',
  'them',
  'then',
  'there',
  'these',
  'they',
  'thing',
  'think',
  'this',
  'thought',
  'three',
  'through',
  'time',
  'too',
  'tree',
  'try',
  'turn',
  'two',
  'under',
  'up',
  'use',
  'very',
  'want',
  'was',
  'water',
  'way',
  'well',
  'went',
  'were',
  'what',
  'when',
  'where',
  'which',
  'while',
  'who',
  'why',
  'will',
  'with',
  'word',
  'work',
  'world',
  'would',
  'write',
  'year',
  'you',
  'your',
  'different',
  'important',
  'therefore',
  'something',
  'education',
  'necessary',
  'knowledge',
  'according',
  'sometimes',
  'community',
  'including',
  'structure',
  'available',
  'treatment',
  'character',
  'president',
  'countries',
  'generally',
  'influence',
  'situation',
  'described',
  'difficult',
  'attention',
  'continued',
  'increased',
  'questions',
  'committee',
  'developed',
  'authority',
  'condition',
  'beginning',
  'relations',
  'published',
  'direction',
  'operation',
  'effective',
  'resources',
  'certainly',
  'principle',
  'materials',
  'potential',
  'presented',
  'statement',
  'financial',
  'existence',
  'reference',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]
